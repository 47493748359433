import React from 'react';
import PropTypes from 'prop-types';
import Box from 'common/src/components/Box';
import Image from 'common/src/components/Image';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Container from 'common/src/components/UI/Container';
import BlockWrapper, {
  ContentWrapper,
  ImageWrapper
} from './humanityBlock.style';

/* ------------------------------------ */
// Humanity block data
/* ------------------------------------ */
// import humanityGlob from 'common/src/assets/image/charity/humanity-glob.png';
import chatIcon from './img/chat_icon.png';

// export const humanityData = {
//   image: humanityGlob,
//   text:
//     'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.',
//   lists: [
//     {
//       id: 1,
//       text: 'Let them drink pure'
//     },
//     {
//       id: 2,
//       text: 'Ensure them medicare'
//     },
//     {
//       id: 3,
//       text: 'create opportunity of education'
//     }
//   ]
// };

const HumanityBlock = ({ row, col }) => {
  // const { slogan, title, text, lists, image } = humanityData;
  return (
    <BlockWrapper id="atencion">
      <Container width="1260px">
        <Box className="row" {...row}>
          <Box className="col" {...col}>
            <ImageWrapper>
              <Image src={chatIcon} alt="Atencion chat online" />
            </ImageWrapper>
          </Box>
          <Box className="col" {...col}>
            <ContentWrapper>
              <Heading as="h5" content="Atención Online" />
              {/* <Heading content="Un enfoque de respuesta en 360°" /> */}
              <Text content="El Programa Aprendiendo y Comunicando[Nos] en Comunidad ofrece atención en línea a través del servicio S.O.S Psicológico, servicio de contención a corto plazo, con el fin de brindar un primer apoyo u orientación en relación a la preocupación o inquietud que aqueja al usuario al momento de acceder al servicio. Este canal de ayuda funciona de lunes a viernes (festivos inclusive) de 8:00 a 22:00 hrs. y es atendido por alumnos del Magíster en Psicología Clínica de la Universidad Adolfo Ibáñez, que son supervisados por psicólogos expertos." />
              <Text>
                La vía de acceso a este servicio es el Chat SOS, al cual pueden
                acceder desde este mismo sitio (ver esquina inferior derecha de
                la pantalla). Se puede acceder a él en cualquier dispositivo con
                conexión a Internet (teléfono celular, tablet, computador o
                notebook). En aquellos casos que requieran un mayor nivel de
                apoyo y contención emocional, los operadores podrán comunicarse
                con el usuario mediante una{' '}
                <u>
                  llamada telefónica de Primeros Auxilios Psicológicos (PAP
                  Telefónico)
                </u>
                .
              </Text>
              {/* <List>
                <Item>Posicionar el cambio de paradigma del aprendizaje</Item>
                <Item>Potenciar el usos de la innovación y tecnología</Item>
                <Item>
                  Movilizar la transformación de la educación pública en Chile
                </Item>
                <Item>Impulsar políticas públicas en educación</Item>
              </List> */}
            </ContentWrapper>
          </Box>
        </Box>
      </Container>
    </BlockWrapper>
  );
};

// HumanityBlock style props
HumanityBlock.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object
};

// HumanityBlock default style
HumanityBlock.defaultProps = {
  // HumanityBlock row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px'
  },
  // HumanityBlock col default style
  col: {
    width: ['100%', '50%', '50%'],
    pl: '15px',
    pr: '15px',
    mb: '30px'
  }
};

export default HumanityBlock;
