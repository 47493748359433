import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Icon } from 'react-icons-kit';
import { linkedin } from 'react-icons-kit/fa/linkedin';
import { facebook } from 'react-icons-kit/fa/facebook';
import { twitter } from 'react-icons-kit/fa/twitter';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Image from 'common/src/components/Image';
import Container from 'common/src/components/UI/Container';
import FooterWrapper, {
  List,
  ListItem,
  CopyrightText,
  SocialList
} from './footer.style';

// import LogoImage from 'common/src/assets/image/charity/logo.svg';
import LogoImage from './img/UAI_METRICS.png';

/* ------------------------------------ */
// Footer data section
/* ------------------------------------ */
export const menuWidgets = [
  {
    id: 1,
    title: 'FUNDRAISE FOR',
    menu: [
      {
        id: 1,
        text: 'Medical',
        link: '#1'
      },
      {
        id: 2,
        text: 'Emergency',
        link: '#1'
      },
      {
        id: 3,
        text: 'Memorial',
        link: '#1'
      },
      {
        id: 4,
        text: 'Education',
        link: '#1'
      },
      {
        id: 5,
        text: 'Charity',
        link: '#1'
      },
      {
        id: 6,
        text: 'Nonprofit organization',
        link: '#1'
      }
    ]
  },
  {
    id: 2,
    title: 'LEARN MORE',
    menu: [
      {
        id: 1,
        text: 'How invisiblechildren works',
        link: '#1'
      },
      {
        id: 2,
        text: 'Pricing and Fees',
        link: '#1'
      },
      {
        id: 3,
        text: 'Common questions',
        link: '#1'
      },
      {
        id: 4,
        text: 'Success stories',
        link: '#1'
      },
      {
        id: 5,
        text: 'Supported countries',
        link: '#1'
      }
    ]
  }
];

const Footer = ({ row, col, colOne, colTwo }) => {
  return (
    <FooterWrapper>
      <Container width="1260px">
        <Box className="row" {...row}>
          <Box className="col-two" {...colTwo}>
            <Box className="col" {...col}>
              <Heading className="widget_title" as="h3" content="Sitio" />
              <List>
                <ListItem>
                  <AnchorLink href="#home" offset="70">
                    Inicio
                  </AnchorLink>
                </ListItem>
                <ListItem>
                  <AnchorLink href="#diplomado" offset="70">
                    Diplomado
                  </AnchorLink>
                </ListItem>
                <ListItem>
                  <AnchorLink href="#talleres" offset="70">
                    Talleres
                  </AnchorLink>
                </ListItem>
                <ListItem>
                  <AnchorLink href="#atencion" offset="70">
                    Atención Online
                  </AnchorLink>
                </ListItem>
                <ListItem>
                  <AnchorLink href="#nosotros" offset="70">
                    Nosotros
                  </AnchorLink>
                </ListItem>
              </List>
            </Box>

            <Box className="col" {...col}>
              {/* <Text className="text" content="+56 9 9999 9999" />
              <Text className="text" content="+56 9 9999 9999" /> */}
              {/* <a
                aria-label="go to bhp"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.bhp.com/"
                className="mail"
              >
                bhp.com
              </a> */}
            </Box>
          </Box>
          {/* End of List column */}
          <Box className="col-one" {...colOne}>
            <Link to="/" className="footer-logo">
              <Image src={LogoImage} alt="BHP" />
            </Link>
          </Box>
          {/* End of logo column */}
        </Box>
        {/* End of widgets row */}
        <Box className="row copyright" {...row}>
          <CopyrightText>
            <Text className="text" content="© UAI·METRICS, 2020." />
          </CopyrightText>
          <SocialList>
            <li className="linkedin">
              <a
                href="https://www.linkedin.com/"
                aria-label="social share link"
              >
                <Icon icon={linkedin} />
              </a>
            </li>
            <li className="facebook">
              <a
                href="https://www.facebook.com/"
                aria-label="social share link"
              >
                <Icon icon={facebook} />
              </a>
            </li>
            <li className="twitter">
              <a href="https://twitter.com/" aria-label="social share link">
                <Icon icon={twitter} />
              </a>
            </li>
          </SocialList>
        </Box>
        {/* End of copyright row */}
      </Container>
    </FooterWrapper>
  );
};

// Footer style props
Footer.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  colOne: PropTypes.object,
  colTwo: PropTypes.object
};

// Footer default style
Footer.defaultProps = {
  // Footer row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px'
  },
  // Footer col one style
  colOne: {
    width: ['100%', '30%', '35%', '30%'],
    mt: [0, '13px', '0'],
    mb: ['30px', 0],
    pl: ['15px', 0],
    pr: ['15px', '15px', 0]
  },
  // Footer col two style
  colTwo: {
    width: ['100%', '70%', '65%', '70%'],
    flexBox: true,
    flexWrap: 'wrap'
  },
  // Footer col default style
  col: {
    width: ['100%', '50%', '50%', '33.33%'],
    pl: '15px',
    pr: '15px',
    mb: '30px'
  }
};

export default Footer;
